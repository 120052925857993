<script setup lang="ts">
// Properties and events.
//
const emit = defineEmits(['process:close-panel'])
const props = defineProps<{
  panelTitle: string
  panelState: boolean
}>()
// Main variables.
//
const panelTitle = toRef(props, 'panelTitle')
const panelState = toRef(props, 'panelState')
// Functions.
//
const closePanel = function closePanel() {
  emit('process:close-panel', false)
}
</script>

<template>
  <div class="rk-form rk-form--filters" :class="{ show: panelState }">
    <Btn
      base-style="plain"
      style-opts="rk-btn--icon btn-close text-reset position-absolute top-0 end-0 p-2"
      @process:click="closePanel"
    >
      <i class="rk-icon rk-icon--text-sm fontello icon-sys-close"></i>
    </Btn>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12 mb-2">
          <h6>{{ panelTitle }}:</h6>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss"></style>
